.progress-circle {
    position: relative;

    margin: 0 auto;
  }

  .progress-circle svg {
    width: 100%;
    height: 100%;
  }

  .progress-circle circle {
    stroke-width: 24;
    fill: none;
  }

  .progress-circle circle:last-child {
    transform: rotate(-90deg);
    transform-origin: center;
    transition: stroke-dasharray 0.5s ease;
  }

