@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 0;
  }
  50% {
    transform: scale(1.01);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

.pulse-animation {
  animation: pulse 3s infinite;
  animation-timing-function: ease-in;
  transition: 0.8s;
}
