.pointer {
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
}

::-webkit-scrollbar-track {
  display: none !important;
  border-radius: 3px !important;
}

::-webkit-scrollbar-thumb {
  background: rgba(234, 243, 255, 0.6) !important;
  border-radius: 3px !important;
  cursor: pointer !important;
}

.gap-4 {
  gap: 4px !important;
}

.gap-6 {
  gap: 6px !important;
}

.gap-8 {
  gap: 8px !important;
}

.gap-12 {
  gap: 12px !important;
}

.gap-16 {
  gap: 16px !important;
}

.gap-18 {
  gap: 18px !important;
}

.gap-20 {
  gap: 20px !important;
}

.gap-24 {
  gap: 24px !important;
}

.gap-28 {
  gap: 28px !important;
}

.gap-32 {
  gap: 32px !important;
}

.wasp-image-big {
  cursor: pointer;
  max-height: 100px;
  max-width: 600px;
  width: auto;
}

.disabled {
  color: #b3b3b3 !important;
  cursor: not-allowed !important  ;
}

.disabled:hover {
  color: #b3b3b3 !important;
}

#bubble-chart {
  border-radius: 8px;
  background: linear-gradient(
    45deg,
    #07a1c3d6 0%,
    #ffbf00d7 33%,
    #ff8800cf 66%,
    #cc1964e2 100%
  );
}

.fully-justify {
  text-align: justify !important;
  text-justify: inter-word !important;
}

.badge-ward-wrap {
  word-wrap: break-word !important;
  overflow: visible !important;
  white-space: normal !important;
  height: 100% !important;
  max-width: 100% !important;
}

blockquote {
  margin: 0;
  margin-left: 20px;
  margin-bottom: 10px;
  font-size: 15px;
  color: rgb(101, 103, 107);
  border-left-color: rgb(206, 208, 212);
  border-left-width: 4px;
  border-left-style: solid;
  padding-left: 16px;
}

pre {
  background-color: rgb(240, 242, 245);
  font-family: Menlo, Consolas, Monaco, monospace;
  display: block;
  padding: 8px 8px 8px 52px;
  line-height: 1.53;
  font-size: 13px;
  margin: 0;
  margin-top: 8px;
  margin-bottom: 8px;
  overflow-x: auto;
  position: relative;
  tab-size: 2;
  border-radius: 4px;
  color: #212529;
}

pre:before {
  content: attr(data-gutter);
  position: absolute;
  background-color: #eee;
  left: 0;
  top: 0;
  border-right: 1px solid #ccc;
  padding: 8px;
  color: #777;
  white-space: pre-wrap;
  text-align: right;
  min-width: 25px;
  height: 100%;
}

.truncate-7-rows {
  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: calc(11 * 7); /* Line height times the number of lines you want */
}

@keyframes flash {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
}

@media print {
  @page {
    size: A4 portrait;
    margin: 0px;
  }

  html,
  body {
    print-color-adjust: exact;
  }

  footer {
    position: fixed;
    bottom: 5px;
  }
  header {
    position: fixed;
    top: 5px;
  }

  pre {
    white-space: wrap !important;
    page-break-inside: avoid !important;
    padding-top: 50px !important;
    max-height: 850px !important;
    overflow-y: clip !important;
  }

  img,
  svg {
    page-break-inside: avoid;
  }

  .mt-5 {
    margin-top: 80px !important;
  }

  #screen-layout {
    position: initial !important;
  }
  #top-navbar {
    display: none !important;
  }
  #left-navbar {
    display: none !important;
  }

  .client-html {
    overflow-wrap: "anywhere";
    z-index: 6;
    background-color: white;
    word-wrap: break-word !important;
    display: block !important;
  }

  .pb_after {
    page-break-after: always !important;
  }

  .box {
    page-break-inside: avoid !important;
    background: transparent !important;
  }

  .border-bottom {
    border-width: 0 0 1px 0 !important;
    border-radius: 0 !important;
  }

  .wasp-image-big {
    width: 100% !important;
    height: auto !important;
    max-width: unset !important;
    max-height: 810px !important;
    border: 1px solid #cecece !important;
    border-radius: 4px !important;
    padding: 4px !important;
    margin: 30px 4px !important;
  }

  .table-head {
    position: relative !important;
  }
}
