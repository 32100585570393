/* funnel-graph */
.fg-container {
  position: absolute;
  top: 10px;
}
.fg-label__segments li {
  color: black !important;
}
/* Hide original legend and labels */
.fg-sub-label,
.fg-label__percentage,
.fg-label__title,
.fg-label__value {
  display: none !important;
}

.fg-label__segments ul {
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-evenly !important;
  gap: 8px !important;
}

.fg-label__segment-item,
.fg-label__segment li {
  margin: 0px !important;
  font-size: 16px !important;
  line-height: 32px !important;
}
